/* eslint-disable import/prefer-default-export */
export const SKYMILL = 'Skymill'
export const MENU = [
  {
    link: '/the-band',
    label: 'The Band',
  },
  {
    link: '/what-we-play',
    label: 'What we play',
  },
  {
    link: '/book-us',
    label: 'Book us',
  },
  {
    link: 'https://careers.skymill.io/jobs',
    label: 'Audition',
  },
]
