import { compiler } from 'markdown-to-jsx';

const parseObject = (data) => {
  const parsedObj = {};
  const urlPattern = new RegExp('^(https?:\\/\\/)?' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  const imgPattern = new RegExp(/\.(gif|jpg|jpeg|tiff|png)$/i);

  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i += 1) {
    if (typeof data[keys[i]] === 'string' && !urlPattern.test(data[keys[i]]) && !imgPattern.test(data[keys[i]])) {
      parsedObj[keys[i]] = compiler(data[keys[i]]);
    } else {
      parsedObj[keys[i]] = data[keys[i]];
    }
  }
  return parsedObj;
};

// eslint-disable-next-line import/prefer-default-export
export const markdownParser = (data) => {
  if (typeof data === 'string') {
    return compiler(data);
  } if (Array.isArray(data)) {
    const parsedArr = [];
    for (let x = 0; x < data.length; x += 1) {
      parsedArr.push(parseObject(data[x]));
    }
    return parsedArr;
  } if (typeof data === 'object') {
    return parseObject(data);
  }
  return null;
};

// shuffle array
export function shuffle(array) {
  const tempArray = [...array];
  for (let i = tempArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
  }

  return tempArray;
}
