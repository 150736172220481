import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import s from './Container.scss';

const Container = ({
  children,
  flexDirection,
  justifyCenter,
  alignCenter,
  justifySpaceBetween,
  justifySpaceAround,
  className,
}) => {
  const cx = classNames(
    'container',
    flexDirection,
    justifyCenter && 'justifyCenter',
    justifySpaceBetween && 'justifySpaceBetween',
    justifySpaceAround && 'justifySpaceAround',
    alignCenter && 'alignCenter',
    className,
  );
  return (
    <div className={cx}>
      {children}
    </div>
  );
};
Container.defaultProps = {
  children: null,
  flexDirection: 'flexRow',
  justifyCenter: false,
  alignCenter: false,
  justifySpaceBetween: false,
  justifySpaceAround: false,
  className: '',
};

Container.propTypes = {
  children: PropTypes.node,
  flexDirection: PropTypes.string,
  justifyCenter: PropTypes.bool,
  justifySpaceBetween: PropTypes.bool,
  justifySpaceAround: PropTypes.bool,
  alignCenter: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(s)(Container);
