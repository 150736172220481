import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import s from './Button.scss';

const Button = ({
  text, design, isSubmit, uppercase,
}) => {
  const cx = classNames('Button', design, uppercase && 'uppercase');
  return (<button className={cx} type={isSubmit ? 'submit' : 'button'}>{text}</button>);
};

Button.defaultProps = {
  text: '',
  design: 'main',
  isSubmit: false,
  uppercase: false,
};

Button.propTypes = {
  text: PropTypes.string,
  design: PropTypes.oneOf(['main', 'simple', 'accent', 'dark', 'simplePrimary']),
  isSubmit: PropTypes.bool,
  uppercase: PropTypes.bool,
};

export default withStyles(s)(Button);
