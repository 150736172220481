import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import s from './Header.scss';
import skymillLogo from '../../../static/images/skymill-logo.svg';
import Container from '../Container';
import MobileHeader from './Mobile';
import { MENU } from '../../../static/constants';

const Header = () => {
  useEffect(() => {
    const debounce = (fn) => {
      let frame;

      return (...params) => {
        if (frame) {
          cancelAnimationFrame(frame);
        }

        frame = requestAnimationFrame(() => {
          fn(...params);
        });
      };
    };

    const storeScroll = () => {
      if (!document) {
        return;
      }

      document.documentElement.dataset.scroll = window.scrollY;
    };

    document.addEventListener('scroll', debounce(storeScroll), {
      passive: true,
    });

    storeScroll();

    return document.removeEventListener('scroll', debounce(storeScroll), {
      passive: true,
    });
  }, []);

  return (
    <header className="Header">
      <MobileHeader />
      <Container justifySpaceBetween alignCenter>
        <Link to="/" className="navigation__logo">
          <img src={skymillLogo} className="logo" alt="logo" />
        </Link>
        <nav className="navbar">
          {MENU.map((key) => {
            if (key.label === 'Audition') {
              return (
                <a href={key.link} className="navigation" key={key.link}>
                  {key.label}
                </a>
              );
            }
            return (
              <Link
                to={key.link}
                className="navigation"
                key={key.link}
                activeClassName="active"
                partiallyActive
              >
                {key.label}
              </Link>
            );
          })}
        </nav>
      </Container>
    </header>
  );
};
export default withStyles(s)(Header);
