import React from 'react';
import { Link } from 'gatsby';
import { withStyles } from '@material-ui/core';
import s from './Footer.scss';
import Container from '../Container';
import footerContent from '../../../content/footer/footer_content.json';
import { markdownParser } from '../../utils';
import skymillBw from '../../../static/images/skymill-bw.svg';
import { MENU, SKYMILL } from '../../../static/constants';
import Button from '../Button';

const parsedFooterContent = markdownParser(footerContent);

const Footer = () => {
  const { buttonText, buttonLink } = footerContent || {};
  const {
    seSkymill,
    dkSkymill,
    socialMedia,
    profile,
    copyright,
  } = parsedFooterContent;

  return (
    <footer className="Footer">
      <div className="mobile-logo">
        <img
          className="mobile-only"
          src={skymillBw}
          alt="Skymill logotype in black and white"
        />
      </div>
      <Container className="container">
        <div className="item">
          <div>
            <h6>{SKYMILL}</h6>
            <ul>
              {MENU.map(({ link, label }) => (
                <li key={link}>
                  {link.startsWith('/') ? (
                    <Link to={link}>{label}</Link>
                  ) : (
                    <a href={link}>{label}</a>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="item-icons">
            <h6>More of us</h6>
            <div className="icon-container">
              <a href={socialMedia.instagram}>
                <i className="fab fa-instagram" />
              </a>
              <a href={socialMedia.facebook}>
                <i className="fab fa-facebook-square" />
              </a>
              <a href={socialMedia.linkedin}>
                <i className="fab fa-linkedin" />
              </a>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="address">
            <h6>{seSkymill.heading}</h6>
            <span>{seSkymill.title}</span>
            <span>{seSkymill.attention}</span>
            <span>{seSkymill.streetNr}</span>
            <span>
              {seSkymill.zip} {seSkymill.city}
            </span>
          </div>
          <div className="address">
            <h6>{dkSkymill.heading}</h6>
            <span>{dkSkymill.title}</span>
            <span>{dkSkymill.attention}</span>
            <span>{dkSkymill.streetNr}</span>
            <span>
              {dkSkymill.zip} {dkSkymill.city}
            </span>
          </div>
        </div>
        <div className="item profile">
          <img src={skymillBw} alt="Skymill logotype in black and white" />
          <div>{profile}</div>
          <Link to={buttonLink || ''}>
            <Button type="button" design="simple" uppercase text={buttonText} />
          </Link>
        </div>
      </Container>
      <p className="footnote">{copyright}</p>
    </footer>
  );
};

export default withStyles(s)(Footer);
