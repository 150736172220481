import { Link } from 'gatsby';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import s from './Mobile.scss';
import skymillLogo from '../../../static/images/skymill-logo.svg';
import burgerMenu from '../../../static/images/menu.svg';
import { MENU } from '../../../static/constants';

const Mobile = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div id="header-mobile">
      <div id="toplevel-mobile">
        <div className="spacer" />
        <Link to="/" className="navigation__logo">
          <img src={skymillLogo} className="logo" alt="logo" />
        </Link>

        <div className="spacer">
          <button type="button" onClick={() => setOpen(!isOpen)}>
            <img src={burgerMenu} alt="Menu" />
          </button>
        </div>
      </div>

      {isOpen && (
        <nav className="mobile">
          <ul>
            {MENU.map((key) => {
              if (key.label === 'Audition') {
                return (
                  <a key={key.link} href={key.link} className="navigation">
                    {key.label}
                  </a>
                );
              }
              return (
                <Link
                  key={key.link}
                  to={key.link}
                  className="navigation"
                  activeClassName="active"
                  partiallyActive
                >
                  {key.label}
                </Link>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};
export default withStyles(s)(Mobile);
