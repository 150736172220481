import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Seo from './seo';

const Layout = ({ location, children }) => {
  // eslint-disable-next-line no-undef
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  return (
    <>
      <Seo title="Skymill" />
      <div data-is-root-path={isRootPath}>
        <Header />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
